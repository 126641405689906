import { Grid } from "@mui/material";
import React from "react";
import "../config/styles/mainCSS.css";
import i18next from "i18next";
import logo from "../shared/assets/Ecorridor.svg";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { logout } from '../auth/service/oauth2Service';

export const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Grid flexDirection="row" className="headerContainer">
        <Link to="/">
          <img src={logo} className="logo" alt="Logo" />
        </Link>
        <Link to="/tripPlanner" className="links">
          {`${i18next.t("shared:headerLinks.plan_trip")}`}
        </Link>
        <Link to="/tripResponse" className="links">
          {`${i18next.t("shared:headerLinks.current_trip")}`}
        </Link>
        <Link to="/profile" className="links">
          {`${i18next.t("shared:headerLinks.profile")}`}
        </Link>
        <div onClick={logout} className="links">
          {`${i18next.t("shared:headerLinks.logout")}`}
        </div>
      </Grid>

      <Grid flexDirection="row" className="headerMobile">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <DehazeIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{}}
        >
          <MenuItem onClick={handleClose}>
            <Link to="/">
              <img src={logo} className="logo" alt="Logo" />
            </Link>
            <Link to="/tripPlanner" className="links">
              {`${i18next.t("shared:headerLinks.plan_trip")}`}
            </Link>
            <Link to="/tripResponse" className="links">
              {`${i18next.t("shared:headerLinks.current_trip")}`}
            </Link>
            <Link to="/profile" className="links">
              {`${i18next.t("shared:headerLinks.profile")}`}
            </Link>
            <div onClick={logout} className="links">
              {`${i18next.t("shared:headerLinks.logout")}`}
            </div>
          </MenuItem>
        </Menu>
      </Grid>
    </>
  );
};
