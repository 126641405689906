import { Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import logo from "../shared/assets/Ecorridor.svg";
import EuroLogo from "../shared/assets/eu2020.png"
import "../config/styles/mainCSS.css";
import i18next from "i18next";
import {
  isAuthenticated,
  requestToken,
  requestOAuth2address,
  requestRegisterAddress,
  saveIdToken,
} from "../auth/service/oauth2Service";
import { useNavigate } from "react-router-dom";
import { showError } from "../shared/util/util.service";

export const HomePage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");

    if (isAuthenticated()) {
      clearUrl()
      navigate("/tripPlanner");
    } else if (code) {
      requestToken(code)
        .then(
          (response: any) => {
            if(response.status === 200) {
              saveIdToken(response.data);
              clearUrl()
              navigate('/tripPlanner');
            }
            else {
              showError('Something went wrong on the authentication flow, please try to login again.');
            }
        })
      .catch(error => {
        console.error(`Error trying to get access token for code '${code}'.`, error);
        if(error.response.status === 502) {
          showError('Something went wrong contacting the OAuth2 server, please try to login again.');
        }
        else {
          showError('Could not complete authentication process, please try to login again.');
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToRegister = (): void => {
    requestRegisterAddress()
      .then((response: any) => {
        if (response.status === 200) {
          const a = document.createElement("a");
          a.target = "_blank";
          a.href = response.data;
          a.click();
        } else {
          console.warn(
            `Received status '${response.status}' getting register address.`
          );
          showError(
            "We couldn't get the required data to proceed with the register, please refresh and try again."
          );
        }
      })
      .catch((error) => {
        console.error("Error getting register url", error);
        showError(
          "Something went wrong contacting our server, please refresh and try again."
        );
      });
  };

  const redirectToOauth2 = (): void => {
    requestOAuth2address()
      .then((response: any) => {
        if (response.status === 200) {
          window.location.href = response.data;
        } else {
          console.warn(
            `Received status '${response.status}' getting oauth2 address.`
          );
          showError(
            "We couldn't get the required data to proceed with the login, please refresh and try again."
          );
        }
      })
      .catch((error) => {
        console.error("Error getting oauth2 url", error);
        showError(
          "Something went wrong contacting our server, please refresh and try again."
        );
      });
  };

  return (
    <Container>
      <Grid container flexDirection="row" justifyContent="center">
        <Grid>
          <img src={logo} className="homeLogo" alt="Logo" />
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            className="homeh1"
            fontWeight="400"
            variant="h1"
          >
            {`${i18next.t("home:home.welcome")}`}
          </Typography>
          <Typography
            fontSize="18px"
            align="center"
            marginTop="4rem"
            whiteSpace="pre-line"
          >
            {`${i18next.t("home:home.description")}`}
          </Typography>
          <span className="buttonContainerHome">
            <Button className="buttonHome" onClick={redirectToOauth2}>
              {`${i18next.t("home:links.sign_in")}`}{" "}
            </Button>
            <Button className="buttonHome" onClick={redirectToRegister}>
              {`${i18next.t("home:links.register")}`}{" "}
            </Button>
          </span>
        </Grid>
        <img src={EuroLogo} className="euLogo" alt="Logo" />
      </Grid>
    </Container>
  );
};


/**
 * Clears the search and hash parts of the url.
 * This is only needed while using the HashRouter.
 */
const clearUrl = () => {
  var cleanUrl = new URL(window.location.href)
  cleanUrl.hash = ''
  cleanUrl.search = ''
  window.history.replaceState(null, "", cleanUrl.toString())
}