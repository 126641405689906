import * as React from "react";
import TextField from "@mui/material/TextField";

export interface GenericFieldProps {
  fieldName: string;
  value?: Date | null | number | string;
  onChange?: (e: any) => void;
}

export const GenericDate: React.FC<GenericFieldProps> = (
  props: GenericFieldProps
) => {
  const { fieldName, value, onChange } = props;

  return (
    <TextField
      sx={{
        margin: "10px",
      }}
      id="outlined-basic"
      variant="outlined"
      name={fieldName}
      required
      onChange={onChange}
      value={value}
      type="datetime-local"
    />
  );
};
