import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { useCallback, useEffect, useState } from "react";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import "../../config/styles/mainCSS.css";
import { GenericInputs } from "../../shared/Genericinputs";
import { GenericDate } from "../../shared/GenericDate";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { tripPlanner } from "../../store";
import { requestUserProfile } from "../../auth/service/oauth2Service";

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

interface GlobalMapWrapperProps {
  children?: React.ReactNode;
}

enum SearchBoxType {
  Origin,
  Destination,
}

export enum TransportMode {
  BUS = "BUS",
  WALK = "WALK",
  CAR = "CAR",
  BICYCLE_RENT = "BICYCLE_RENT",
}

let originMarker: google.maps.Marker | null;
let destinationMarker: google.maps.Marker | null;

export const TripPlannerMap: React.FC<GlobalMapWrapperProps> = (props) => {
  const [clicks, setClicks] = useState<google.maps.LatLng[]>([]);
  const [zoom, setZoom] = useState(5);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: 48.8566,
    lng: 2.3522,
  });

  const onClick = (e: google.maps.MapMouseEvent) => {
    // avoid directly mutating state
    setClicks([...clicks, e.latLng!]);
  };

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
    setCenter(m.getCenter()!.toJSON());
  };

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  return (
    <Grid
      style={{
        margin: "0 auto",
        display: "flex",
        width: "100%",
        height: "100%",
        paddingRight: "10px",
      }}
    >
      <Wrapper
        render={render}
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!!}
        libraries={["places"]}
      >
        <Map
          onClick={onClick}
          onIdle={onIdle}
          center={center}
          zoom={zoom}
          style={{ flexGrow: "1", height: "100%" }}
        ></Map>
      </Wrapper>
    </Grid>
  );
};

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const [map, setMap] = useState<google.maps.Map>();

  var today = moment(new Date()).format("yyyy-MM-DDTHH:mm");

  const defaultValues = {
    origin: "",
    destination: "",
    date: today,
  };

  const [formValues, setFormValues] = useState<any>(defaultValues);
  const [loading, setLoading] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();
  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = moment(new Date(e.target.value)).format("yyyy-MM-DDTHH:mm");
    setFormValues({
      ...formValues,
      date: newDate,
    });
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const originPosition = originMarker?.getPosition()!!;
    const destinationPosition = destinationMarker?.getPosition()!!;
    const origin = `${originPosition.lat()},${originPosition.lng()}`;
    const destination = `${destinationPosition.lat()},${destinationPosition.lng()}`;
    const time = moment(new Date(formValues.date)).format("hh:mma");
    const date = moment(new Date(formValues.date)).format("MM-DD-YYYY");
    const mode = `${TransportMode.BUS},${TransportMode.WALK},${TransportMode.BICYCLE_RENT}`;
    const maxWalkDistance = await requestUserProfile()
      .then((r) => r.data.max_walking_distance)
      .catch(() => 1000);

    setLoading(true);
    tripPlanner(origin, destination, time, date, mode, maxWalkDistance)
      .then((dtoData: any) => {
        if (dtoData && dtoData.plan.itineraries.length > 0) {
          navigate("/tripResponse", { state: dtoData });
        } else {
          setErrorMessage(i18next.t("tripPlanner:infoText.error.no_trip"));
        }
      })
      .catch((error) => {
        setErrorMessage(i18next.t("tripPlanner:infoText.error.generic"));
        console.warn(error);
      })
      .finally(() => {
        setLoading(false);
        handleClear();
        originMarker?.setPosition(null);
      });
  };
  const handleClear = () => {
    setFormValues(defaultValues);
    originMarker?.setMap(null);
    destinationMarker?.setMap(null);
  };

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  const setSearchBoxListener = useCallback(
    (
      myMap: google.maps.Map,
      searchBox: google.maps.places.Autocomplete,
      type: SearchBoxType
    ) => {
      searchBox.addListener("place_changed", () => {
        const places: any = searchBox.getPlace();

        if (!places.geometry || !places.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        createOrUpdateMarker(type, myMap, places);
        const bounds = new google.maps.LatLngBounds();
        if (originMarker) {
          bounds.extend(originMarker.getPosition()!!);
        }
        if (destinationMarker) {
          bounds.extend(destinationMarker.getPosition()!!);
        }
        myMap.setCenter(bounds.getCenter());
        myMap.fitBounds(bounds);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fitBoundsToMarkers = (myMap: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds();
    if (originMarker) {
      bounds.extend(originMarker.getPosition()!!);
    }
    if (destinationMarker) {
      bounds.extend(destinationMarker.getPosition()!!);
    }
    myMap.fitBounds(bounds);
    myMap.setCenter(bounds.getCenter());
  };

  const createOrUpdateMarker = (
    type: SearchBoxType,
    myMap: google.maps.Map,
    place: google.maps.places.PlaceResult
  ) => {
    const icon = {
      url: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png",
      size: new google.maps.Size(71, 71),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(17, 34),
      scaledSize: new google.maps.Size(25, 25),
    };
    const placeLocation = place.geometry!!.location;
    switch (type) {
      case SearchBoxType.Origin:
        originMarker?.setMap(null);
        originMarker = new google.maps.Marker({
          map: myMap,
          icon: icon,
          title: place.name,
          position: placeLocation,
          draggable: true,
        });
        google.maps.event.addListener(originMarker, "dragend", (event) => {
          originMarker!!.setPosition(originMarker!!.getPosition()!!);
        });
        break;
      case SearchBoxType.Destination:
        destinationMarker?.setMap(null);
        destinationMarker = new google.maps.Marker({
          map: myMap,
          icon: icon,
          title: place.name,
          position: placeLocation,
          draggable: true,
        });
        google.maps.event.addListener(destinationMarker, "dragend", (event) => {
          destinationMarker!!.setPosition(destinationMarker!!.getPosition()!!);
        });
        break;
    }
    fitBoundsToMarkers(myMap);
    setFormValues({
      ...formValues,
      destination: destinationMarker && destinationMarker.getPosition(),
      origin: originMarker && originMarker.getPosition(),
    });
  };

  // initialize map and searchboxes
  useEffect(() => {
    const newMap = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {}
    );
    setMap(newMap);
    var options = {
      types: ["address"],
    };
    const orginSearchBox = new google.maps.places.Autocomplete(
      document.getElementById("origin") as HTMLInputElement,
      options
    );
    setSearchBoxListener(newMap, orginSearchBox, SearchBoxType.Origin);

    const destinationSearchBox = new google.maps.places.Autocomplete(
      document.getElementById("destination") as HTMLInputElement,
      options
    );
    setSearchBoxListener(
      newMap,
      destinationSearchBox,
      SearchBoxType.Destination
    );
  }, [setSearchBoxListener]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <Grid container flexDirection="row" className="main-section-wrapper">
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "3rem",
            }}
            component="form"
            noValidate
            autoComplete="off"
          >
            {loading === true ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="success" />
                <Box pt={4} />
                <Typography
                  align="center"
                  color="#55B041"
                  variant="h5"
                >{`${i18next.t("tripPlanner:infoText.loading")}`}</Typography>
              </Box>
            ) : (
              <>
                {errorMessage && (
                  <Box pb={3} display="flex" justifyContent="center">
                    <Alert className="errorHandler" severity="error">
                      <AlertTitle>{`${i18next.t(
                        "tripPlanner:infoText.error.error_title"
                      )}`}</AlertTitle>
                      <strong>{errorMessage}</strong>
                    </Alert>
                  </Box>
                )}

                <Typography align="center" color="#2994B1" variant="h4">
                  {`${i18next.t("tripPlanner:infoText.info")}`}
                </Typography>
                <Typography margin="1rem" align="center">
                  {`${i18next.t("tripPlanner:infoText.search")}`}
                </Typography>
                <FormControl>
                  <GenericInputs
                    fieldName="origin"
                    fieldType="text"
                    id="origin"
                    label="origin"
                  />
                  <GenericInputs
                    fieldName="destination"
                    fieldType="text"
                    id="destination"
                    label="destination"
                  />
                  <GenericDate
                    fieldName="date"
                    value={formValues.date}
                    onChange={onChangeDate}
                  />

                  <span className="buttonContainer">
                    <Button
                      variant="outlined"
                      type="reset"
                      onClick={handleClear}
                    >
                      {`${i18next.t("tripPlanner:buttons.clear")}`}
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={
                        formValues.origin &&
                        formValues.destination &&
                        loading === false
                          ? false
                          : true
                      }
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {`${i18next.t("tripPlanner:buttons.submit")}`}
                    </Button>
                  </span>
                </FormControl>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} className="responsiveHeight" md={8} marginTop="10px">
          <div id="map" style={style} />
        </Grid>
      </Grid>
    </>
  );
};

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  // eslint-disable-next-line
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
