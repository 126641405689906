import * as TRIP_PLANNER_TRANSLATIONS from "../../tripPlanner/locals";
import * as SHARED from '../../shared/locals';
import * as TRIP_RESPONSE from '../../tripResponse/locals';
import * as HOME from '../../home/locals';
import * as PROFILE from '../../profile/locals'
import { default as i18n, default as i18next } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: "en",
    debug: false,
    defaultNS: "common",
    interpolation: {
      escapeValue: false,
    },
  });

i18next.addResourceBundle(
  "en",
  TRIP_PLANNER_TRANSLATIONS.TRIP_PLANNER_NAMESPACE,
  TRIP_PLANNER_TRANSLATIONS.EN
);
i18next.addResourceBundle(
  "en",
  SHARED.SHARED_NAMESPACE,
  SHARED.EN
)
i18next.addResourceBundle(
  "en",
  TRIP_RESPONSE.TRIP_RESPONSE_NAMESPACE,
  TRIP_RESPONSE.EN
)
i18next.addResourceBundle(
  "en",
  HOME.HOME,
  HOME.EN
)
i18next.addResourceBundle(
  "en",
  PROFILE.PROFILE_NAMESPACE,
  PROFILE.EN
)
