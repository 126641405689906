import { Grid } from "@mui/material";
import React from "react";
import { Profile } from "../profile/views/profile";
import { Footer } from "../shared/footer";
import { Header } from "../shared/Header";

export const ProfilePage: React.FC = () => {

  return (
    <Grid>
      <Header/>
      <Profile />
      <Footer/>
    </Grid>
  );
};
