import { Grid } from "@mui/material";
import React from "react";
import { TripPlannerMap } from "../tripPlanner/maps/map";
import { Header } from "../shared/Header";
import { Footer } from "../shared/footer";


export const TripPlanner: React.FC = () => {

  return (
    <Grid>
      <Header/>
      <TripPlannerMap/>
      <Footer/>
    </Grid>
  );
};
