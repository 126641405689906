import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { useEffect, useRef, useState } from "react";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import i18next from "i18next";
import "../../config/styles/mainCSS.css";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { TransportMode } from "../../tripPlanner/maps/map";
import { getIdToken } from "../../auth/service/oauth2Service";
import { Incentives } from "../models";

interface SetPolyMapProp {
  children?: React.ReactNode;
}

interface PolyMapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const legType2color: Map<string, string> = new Map([
  [TransportMode.BUS, "#280A54"],
  [TransportMode.CAR, "#F8AD33"],
  [TransportMode.WALK, "#55B041"],
  ["default", "#EA4335"],
]);

export const SetPolyMap: React.FC<SetPolyMapProp> = (props) => {
  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  return (
    <Grid
      className="main-section-wrapper"
      style={{
        margin: "0 auto",
        display: "flex",
        width: "100%",
      }}
    >
      <Wrapper
        render={render}
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!!}
        libraries={["places"]}
      >
        <PolyMap style={{ height: "100%" }}></PolyMap>
      </Wrapper>
    </Grid>
  );
};

const PolyMap: React.FC<PolyMapProps> = ({ style, ...options }) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [incentives, setIncentives] = useState<Incentives>();
  const [mode, setMode] = useState<Array<object>>([]);
  const [tripDuration, setTripDuration] = useState<any>("");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [co2, setCo2] = useState<number>(0);
  const [itineraryIndex, setItineraryIndex] = useState<number>(0);
  const [originName, setOriginName] = useState<string>("");
  const [destinationName, setDestinationName] = useState<string>("");
  const location: any = useLocation();

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (
      location.state !== null &&
      location.state.plan.itineraries.length > 0 &&
      itineraryIndex >= 0 &&
      itineraryIndex < location.state.plan.itineraries.length
    ) {
      const map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        {
          mapTypeId: "terrain",
        }
      );
      setMap(map);
      setMode(drawLeg(map, location.state.plan.itineraries[itineraryIndex]));
      setStartTime(
        moment(
          location.state.plan.itineraries[itineraryIndex].startTime
        ).format("DD/MM/YYYY hh:mm a")
      );
      setEndTime(
        moment(location.state.plan.itineraries[itineraryIndex].endTime).format(
          "DD/MM/YYYY hh:mm a"
        )
      );
      setIncentives(
        JSON.parse(
          location.state.plan.itineraries[itineraryIndex].microSubsidyAvailable
        )
      );
      const minutes = Math.round(
        (location.state.plan.itineraries[itineraryIndex].endTime -
          location.state.plan.itineraries[itineraryIndex].startTime) /
          60000
      );
      setTripDuration(minToHrs(minutes));
      setCo2(location.state.plan.itineraries[itineraryIndex].co2Value);
      reverseGeoCode(
        {
          lat: parseFloat(location.state.plan.from.lat),
          lng: parseFloat(location.state.plan.from.lon),
        },
        (r: any) => setOriginName(r)
      );
      reverseGeoCode(
        {
          lat: parseFloat(location.state.plan.to.lat),
          lng: parseFloat(location.state.plan.to.lon),
        },
        (r: any) => setDestinationName(r)
      );
    }
  }, [itineraryIndex, location]);

  const minToHrs = (minutes: number) => {
    if (minutes > 59) {
      let hours = 0;
      while (minutes > 59) {
        minutes = minutes - 60;
        hours++;
      }
      return (
        hours +
        i18next.t("tripResponse:tripInfo.hours") +
        minutes +
        i18next.t("tripResponse:tripInfo.minutes")
      );
    } else return minutes + i18next.t("tripResponse:tripInfo.minutes");
  };

  const boundsChange = (from: any, to: any) => {
    if (map) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend({ lat: from.lat, lng: from.lng });
      bounds.extend({ lat: to.lat, lng: to.lng });
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    }
  };

  const reverseGeoCode = (LtdLng: any, callback: Function) => {
    const geocoder = new google.maps.Geocoder();
    if (geocoder) {
      geocoder.geocode({ location: LtdLng }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          callback(results[0].formatted_address);
        }
      });
    }
  };

  const drawLeg = (map: any, legItinerary: any) => {
    const modes: Array<object> = [];
    const bounds = new google.maps.LatLngBounds();
    let markers: google.maps.Marker[] = [];
    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 1.5,
      scale: 5,
    };
    legItinerary.legs.forEach((leg: any) => {
      const steps: any = [];
      steps.push({
        lat: leg.from.lat,
        lng: leg.from.lon,
      });
      markers.push(
        new google.maps.Marker({
          map,
          position: {
            lat: leg.from.lat,
            lng: leg.from.lon,
          },
        })
      );
      leg.steps?.forEach((step: any) => {
        steps.push({
          lat: step.lat,
          lng: step.lon,
        });
      });
      steps.push({
        lat: leg.to.lat,
        lng: leg.to.lon,
      });
      markers.push(
        new google.maps.Marker({
          map,
          position: {
            lat: leg.to.lat,
            lng: leg.to.lon,
          },
        })
      );
      const travelPath = new google.maps.Polyline({
        path: steps,
        geodesic: true,
        strokeColor:
          legType2color.get(leg.mode) ?? legType2color.get("default"),
        strokeOpacity: leg.mode === TransportMode.BUS ? 0.5 : 1.5,
        icons:
          leg.mode === TransportMode.BUS
            ? [
                {
                  icon: lineSymbol,
                  offset: "0",
                  repeat: "25px",
                },
              ]
            : [],
        strokeWeight: 2,
      });

      modes.push({
        type: leg.mode,
        from: {
          departure: leg.from.departure,
          stop: leg.from.stopId?.split(":")[1],
          lat: leg.from.lat,
          lng: leg.from.lon,
          location_id:
            leg.mode === "CAR" ? leg.from.bikeShareId.split(" ")[1] : null,
        },
        to: {
          stop: leg.to.stopId?.split(":")[1],
          lat: leg.to.lat,
          lng: leg.to.lon,
        },
      });
      travelPath.setMap(map);
      steps.map((s: any) => bounds.extend({ lat: s.lat, lng: s.lng }));
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    });
    return modes;
  };

  // open url in new tab with javascript so that we can close it with javascript in /tripConfirmed
  const goToNemi = (data: any) => () => {
    const url = `${
      process.env.REACT_APP_NEMI_URL
    }/new-reservation/route-options?${buildNemiUrl(data)}`;
    console.log(url);
    window.open(url, "_blank")?.focus();
  };

  return (
    <Grid container flexDirection="row" className="main-section-wrapper">
      <Grid item md={4} xs={12} marginTop="10px" pl={4} pr={4}>
        <Typography
          marginBottom="20px"
          align="center"
          color="#2994B1"
          variant="h4"
        >{`${i18next.t("tripResponse:tripInfo.tripInfoHeader")}`}</Typography>

        {location.state === null ? (
          <>
            <Alert className="errorHandler" severity="error">
              <AlertTitle>{`${i18next.t(
                "tripResponse:handlers.error"
              )}`}</AlertTitle>
              <strong>{`${i18next.t("tripResponse:handlers.no_trip")}`}</strong>
            </Alert>
          </>
        ) : (
          <Grid container flexDirection="row" alignItems="baseline">
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-label">{`${i18next.t(
                "tripResponse:tripInfo.select.label"
              )}`}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={itineraryIndex}
                label="Itinerary"
                onChange={(e) => setItineraryIndex(e.target.value as number)}
              >
                {location.state?.plan.itineraries.map(
                  (it: any, index: number) => (
                    <MenuItem key={index} value={index}>
                      {"Itinerary " + (index + 1)}
                    </MenuItem>
                  )
                )}
              </Select>
            </Grid>
            <Grid className="firstCol" marginBottom="1rem" item xs={6}>
              <Typography
                className="tripInfoHeaderText"
                variant="h6"
              >{`${i18next.t("tripResponse:tripInfo.from")}`}</Typography>
              <Typography className="tripDetailInfo">{originName}</Typography>
              <Typography
                className="tripInfoHeaderText"
                variant="h6"
              >{`${i18next.t("tripResponse:tripInfo.startTime")}`}</Typography>
              <Typography className="tripDetailInfo">{startTime}</Typography>
              <Typography
                className="tripInfoHeaderText"
                variant="h6"
              >{`${i18next.t("tripResponse:tripInfo.duration")}`}</Typography>
              <Typography className="tripDetailInfo">{tripDuration}</Typography>
            </Grid>
            <Grid item marginBottom="1rem" xs={6}>
              <Typography
                className="tripInfoHeaderText"
                variant="h6"
              >{`${i18next.t("tripResponse:tripInfo.to")}`}</Typography>
              <Typography className="tripDetailInfo">
                {destinationName}
              </Typography>

              <Typography
                className="tripInfoHeaderText"
                variant="h6"
              >{`${i18next.t("tripResponse:tripInfo.endTime")}`}</Typography>
              <Typography className="tripDetailInfo">{endTime}</Typography>

              <Typography
                className="tripInfoHeaderText"
                variant="h6"
              >{`${i18next.t("tripResponse:tripInfo.co2")}`}</Typography>

              <Typography marginBottom="20PX" className="tripDetailInfo">
                {co2 + i18next.t("tripResponse:tripInfo.grams")}
              </Typography>
            </Grid>

            <Grid item marginBottom="1rem" xs={12}>
              <Paper variant="outlined">
                <Box p={2}>
                  <Typography className="tripInfoHeaderText" variant="h6">
                    {`${i18next.t("tripResponse:tripInfo.mode")}`}
                  </Typography>
                  {mode &&
                    mode.map((m: any, i: number) => (
                      <Box key={i}>
                        {!!i && <Divider />}
                        <Button
                          fullWidth
                          color="primary"
                          onClick={() => boundsChange(m.from, m.to)}
                        >
                          {m.type}
                        </Button>
                        {m.type === TransportMode.BUS && (
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            m={1}
                          >
                            <Typography color="secondary">
                              Discount: {getProviderDiscount("Nemi", incentives)}€
                            </Typography>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={goToNemi(m)}
                            >
                              {`${i18next.t("tripResponse:links.nemi")}`}
                            </Button>
                          </Box>
                        )}

                        {m.type === TransportMode.CAR && (
                          <Box display="flex" mb={2}>
                            <Typography color="secondary">
                              Discount: {getProviderDiscount("Clem", incentives)}€
                            </Typography>
                            <Button
                              color="primary"
                              variant="outlined"
                              href={`http://e-corridor.clem.mobi/autologin?location_id=${m.from.location_id}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {`${i18next.t("tripResponse:links.clem")}`}
                            </Button>
                          </Box>
                        )}
                      </Box>
                    ))}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Link to="/tripPlanner" className="links">
                <Button variant="outlined">{`${i18next.t(
                  "tripResponse:links.finish"
                )}`}</Button>
              </Link>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={8} className="responsiveHeight" marginTop="10px">
        <div id="map" style={style} />
      </Grid>
    </Grid>
  );
};

const getProviderDiscount = (providerName: string, incentives?: Incentives) => {
  if (!incentives) return 0;
  const subsidy = incentives.legs.find(
    (leg) => leg.rideProviderName === providerName
  );
  if (!subsidy) return 0;
  return subsidy.subsidizedValue;
};

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  // eslint-disable-next-line
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

function buildNemiUrl(leg: any) {
  const params = new URLSearchParams({
    service: "97185",
    origin: leg.from.stop,
    dest: leg.to.stop,
    passengers: "1",
    prm: "0",
    datetime: leg.from.departure,
    specificDate: "false",
    token: getIdToken()!!,
    env: process.env.REACT_APP_NEMI_ENV!!,
  });
  return params.toString();
}
