import { Grid } from "@mui/material";
import React from "react";
import { HomePage } from "../home/homePage";

export const Home: React.FC = () => {

  return (
    <Grid>
      <HomePage></HomePage>
    </Grid>
  );
};