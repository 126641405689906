import * as React from "react";

import TextField from "@mui/material/TextField";

export interface GenericFieldProps {
  fieldName: string;
  value?: string;
  label: string;
  fieldType: string;
  id?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
}

export const GenericInputs: React.FC<GenericFieldProps> = (
  props: GenericFieldProps
) => {
  const { fieldName, label, value, fieldType, id, onChange, onBlur, onFocus } =
    props;

  return (
    <TextField
      sx={{
        margin: "10px",
      }}
      type={fieldType}
      id={id}
      value={value}
      name={fieldName}
      label={label}
      variant="outlined"
      required
      placeholder="Please search for an address"
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};
