import React, { useEffect } from "react";

export const TripConfirmed: React.FC = () => {
  useEffect(() => {
    window.close();
  }, []);

  return (
    <div>
      This page should automatically close itself. If you can read this, please
      close this tab to return to the trip planner.
    </div>
  );
};
