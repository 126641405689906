import { Box, Button, Container, Grid, TextField } from "@mui/material";
import i18next from "i18next";
import { requestUserProfile } from "../../auth/service/oauth2Service";
import { useEffect, useState } from "react";
import { showError } from "../../shared/util/util.service";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const Profile: React.FC = () => {
  const [profileData, setProfileData] = useState<any>();

  useEffect(() => {
    requestUserProfile()
      .then((response) => {
        setProfileData(response.data);
      })
      .catch((error) => {
        console.error("Error getting user profile", error);
        showError(`${i18next.t("profile:errors.fetchingProfile")}`)
      });
  }, []);

  const openEditProfile = () => {
    window.open(process.env.REACT_APP_EDIT_PROFILE_URL, "_blank");
  }

  return (
    <Container>
      <Grid
        className="profileContainer"
        item
        margin="0rem auto"
        flexDirection="column"
        md={6}
        xs={12}
      >
        <h2>{`${i18next.t("profile:title")}`}</h2>
        {profileData && (
          <>
            <Field label="Full Name" value={profileData.name} />
            <Field label="Email" value={profileData.email} />
            <Field label="Birthday" value={profileData.birthdate} />
            <Field label="Gender" value={profileData.gender} />
            <Field label="Address" value={profileData.address} />
            <Field label="Phone Number" value={profileData.phone_number} />
            <Field label="Driver's License" value={profileData.driver_license} />
            <Field label="Max Walking Distance" value={profileData.max_walking_distance} />
          </>
        )}
      </Grid>
      <Box display="flex" justifyContent="center">
        <Button className="footerButton" onClick={openEditProfile} startIcon={<OpenInNewIcon />}>
          {`${i18next.t("profile:actions.edit")}`}
        </Button>
      </Box>
    </Container>
  );
};

interface FieldProps {
  label: string;
  value: string;
}

const Field: React.FC<FieldProps> = ({ label, value }) => (
  <TextField
    fullWidth
    margin="dense"
    label={label}
    defaultValue={value}
    InputProps={{ readOnly: true }}
    variant="standard"
  />
)