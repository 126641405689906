import { httpClient } from './../../shared/util/http-client';
import { TokenResponse } from "../model/oauth2Model";

const LOCAL_STORAGE_ID_TOKEN = 'auth_it'
const LOCAL_STORAGE_EXPIRATION = 'auth_exp'

export const getIdToken = (): string | null => {
  return localStorage.getItem(LOCAL_STORAGE_ID_TOKEN);
}

const getAuthExpirationDate = (): number | null => {
  return parseInt(localStorage.getItem(LOCAL_STORAGE_EXPIRATION) || "");
}

export const isAuthenticated = (): boolean => {
  const token = getIdToken();
  const expirationDate = getAuthExpirationDate();
  if (!token || !expirationDate) return false;

  const validToken = expirationDate > new Date().getTime();
  if(!validToken) {
    localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN);
  }
  return validToken;
}

export const requestOAuth2address = (): Promise<string> => {
  return httpClient().get(`${process.env.REACT_APP_BACKEND_ADDRESS}/auth`);
}

export const requestRegisterAddress = (): Promise<string> => {
  return httpClient().get(`${process.env.REACT_APP_BACKEND_ADDRESS}/auth/register`);
}

export const requestToken = (code: string): Promise<TokenResponse> => {
  return httpClient().get(`${process.env.REACT_APP_BACKEND_ADDRESS}/auth/token`, { params: { code } });
}

export const requestUserProfile = (): Promise<any> => {
  return httpClient().get(`${process.env.REACT_APP_BACKEND_ADDRESS}/auth/profile`);
}

export const logout = () => {
  localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN);
  window.location.href = "/";
}

export const saveIdToken = (data: any) => {
  localStorage.setItem(LOCAL_STORAGE_ID_TOKEN, data.id_token)
  localStorage.setItem(LOCAL_STORAGE_EXPIRATION, (data.expires_at * 1000).toString())
}