import { isAuthenticated, getIdToken } from '../../auth/service/oauth2Service';
import axios, { AxiosInstance } from 'axios';

export const httpClient = (): AxiosInstance => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_PATH,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    if(isAuthenticated()) {
      const token = getIdToken()!!;
      config.headers!!.Authorization =  `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};
