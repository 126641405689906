import { httpClient } from "../shared/util/http-client";

export const tripPlanner = async (
  origin: string,
  destination: string,
  time: string,
  date: string,
  mode: string,
  maxWalkDistance: number
) => {
  const baseUrl = process.env.REACT_APP_BACKEND_ADDRESS;

  // start background job
  const token = await httpClient()
    .post(`${baseUrl}/analytics/build`, {
      fromPlace: origin,
      toPlace: destination,
      time: time,
      date: date,
      mode: mode,
      maxWalkDistance: maxWalkDistance.toString(),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data.token;
      }
    })
    .catch((error) => {
      console.error(error);
    });

  if (!token) return;

  // wait for background job to finish
  while (true) {
    let errorOcurred = false;
    let dpoData: any;

    await delay(2000);

    await httpClient()
      .post(`${baseUrl}/analytics/ping`, {
        token: token,
      })
      .then((response) => {
        if (response.status === 200 && response.data.finished) {
          dpoData = response.data.data;
        } else if (response.data.failed) {
          errorOcurred = true;
        }
      })
      .catch((error) => {
        console.error(error);
        errorOcurred = true;
      });

    if (dpoData || errorOcurred) return dpoData;
  }
};

const delay = async (milliseconds: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
};
