import "./App.css";
import { TripPlanner } from "./pages/tripPlan";
import { TripResponse } from "./pages/tripResponse";
import { TripConfirmed } from "./pages/tripConfirmed";
import { Routes, Route, HashRouter } from "react-router-dom";
import { Home } from "./pages/home";
import { RequireAuth } from "./auth/service/requireAuth";
import { Toaster } from "react-hot-toast";
import { ProfilePage } from "./pages/profile";
import CookieConsent from "react-cookie-consent";
import { Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import i18next from "i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2994B1",
    },
    secondary: {
      main: "#448c34",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <CookieConsent
          overlay
          location="bottom"
          buttonText="Accept"
          cookieName="S2C-WebApp-Cookies"
          style={{ background: "#2994B1" }}
          buttonStyle={{
            background: "#55B041",
            color: "#FFFFFF",
            fontSize: "13px",
          }}
          expires={150}
        >
          <Typography>{`${i18next.t("shared:cookies.titleText")}`}</Typography>
          <span style={{ fontSize: "10px" }}>
            {`${i18next.t("shared:cookies.undercase")}`}
          </span>
        </CookieConsent>
        <Routes>
          <Route path={`/`} element={<Home />} />
          <Route
            path={`/tripPlanner`}
            element={
              <RequireAuth>
                <TripPlanner />
              </RequireAuth>
            }
          />
          <Route
            path={`/tripResponse`}
            element={
              <RequireAuth>
                <TripResponse />
              </RequireAuth>
            }
          />
          <Route path={`/tripConfirmed`} element={<TripConfirmed />} />
          <Route
            path={`/profile`}
            element={
              <RequireAuth>
                <ProfilePage />
              </RequireAuth>
            }
          />
        </Routes>
      </HashRouter>
      <CssBaseline />
      <Toaster />
    </ThemeProvider>
  );
}

export default App;
