import { Grid } from "@mui/material";
import React from "react";
import { Footer } from "../shared/footer";
import { Header } from "../shared/Header";
import { SetPolyMap } from "../tripResponse/map/polyMap";

export const TripResponse: React.FC = () => {

  return (
    <Grid>
      <Header/>
      <SetPolyMap/>
      <Footer/>
    </Grid>
  );
};
