import { Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import React from "react";
import "../config/styles/mainCSS.css";
import i18next from "i18next";
import logo from "../shared/assets/Ecorridor.svg";
import { Link } from "react-router-dom";

export const Footer: React.FC = () => {
  return (
    <>
      <Grid className="footerContainer">
        <Grid item md={6} xs={12} className="pageLinks">
          <Link to="/">
            <img src={logo} className="logo" alt="Logo" />
          </Link>
          <Link to="/tripPlanner" className="links">
            {`${i18next.t("shared:headerLinks.plan_trip")}`}
          </Link>
          <Link to="/tripResponse" className="links">
            {`${i18next.t("shared:headerLinks.current_trip")}`}
          </Link>
          {/* <Link to="/profile" className="links">
            {`${i18next.t("shared:headerLinks.profile")}`}
          </Link> */}
        </Grid>
        <Grid item md={6} xs={12} textAlign="center">
          <a
            href="https://www.facebook.com/ECORRIDOR.EU"
            rel="noreferrer"
            target="_blank"
          >
            <FacebookIcon className="links" />
          </a>
          <a
            href="https://twitter.com/ECORRIDOR_EU"
            rel="noreferrer"
            target="_blank"
          >
            <TwitterIcon className="links" />
          </a>
          <a
            href="https://www.linkedin.com/in/ECORRIDOR"
            rel="noreferrer"
            target="_blank"
          >
            <LinkedInIcon className="links" />
          </a>
        </Grid>
      </Grid>
    </>
  );
};
